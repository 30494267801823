import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import queryString from 'query-string';
import CasesDetail from '@/components/Cases/CasesDetail';

const CasesPage: React.FC<PageProps<GatsbyTypes.CasesPageQuery>> = ({
  location,
}) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<GatsbyTypes.CasesPageQuery | null>(null);

  useEffect(() => {
    fetch(
      `https://nrinetcom.microcms.io/api/v1/case/${contentId}?draftKey=${draftKey}`,
      {
        headers: {
          'X-MICROCMS-API-KEY': `8c58103eb75849318c389a68418162054552`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => setData({ microcmsCase: res }));
  }, []);

  if (data === null) {
    const urlParam = location.search.substring(1);
    if (urlParam === `` && typeof window !== `undefined`) {
      window.location.href = `/404`;
    }
    return null;
  }

  return <CasesDetail data={data} />;
};

export default CasesPage;
